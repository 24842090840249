<script setup lang="ts">
const cartStore = useCartStore();
const onboardingStore = useOnboardingStore();

const bannerTypeToShow = computed(() => cartStore.bannerTypeToShow);
const { promoCodeBanner } = storeToRefs(cartStore);

const isVisible = computed(() => {
  if (onboardingStore.isPromoCodeMultiTiers) {
    return false;
  }

  if (bannerTypeToShow.value !== "discount") {
    return false;
  }

  return promoCodeBanner.value && promoCodeBanner.value.showBanner;
});

const bannerDescription = computed(() => promoCodeBanner.value?.description);
</script>

<template>
  <div
    v-if="isVisible && promoCodeBanner && bannerDescription"
    class="tw-sticky tw-top-0 tw-right-0 tw-left-0 tw-z-50 promo-banner tw-bg-nl-green-100 tw-h-[45px] tw-text-nl-yellow-100 tw-font-agenda tw-flex tw-items-center tw-justify-center tw-uppercase tw-font-semibold tw-tracking-wide tw-text-center tw-leading-[1.04] tw-px-5"
  >
    {{ bannerDescription }}
  </div>
</template>
<style scoped>
@import url('~/assets/css/app.css');
</style>
